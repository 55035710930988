body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Carousel-Container-sv {
  margin-top: -50px;
}

.sv-card {
  margin: 5px;

}

.sv-card:hover {
  cursor: pointer;
  margin-top: -1px;
}

.sv-card-img:hover {
  box-shadow: 0px 0px 10px 5px #f5e4e4;
}

.sv-card-img {
  border-radius: 10px;
  max-width: 100vw;
  height: 70vh;
  box-shadow: 0px 0px 10px 5px #ccc;


	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.sv-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.sv-card-title {
  font-size: 24px;
  margin: 0px;
}

.sv-card-subTitle {
  font-size: 16px;
  margin: 0px;
}

/* About Me Section */
.about-me-container {
  margin-top: 50px;
}

.about-left-card {
  color: white;
  border-radius: 2px;
  background-color: gray;
  padding: 5px;
}

.goto-button {
  background-color: #212529;
  color: white;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 5;
  text-decoration: none;
  margin-top: 10px;
}

.goto-button:focus {
  outline: none;
}

.project-page-container {
  margin-top: 25px;
}

.Project-Card-Row img {
  margin-top: 10px;
  width: 250px;
  height: 350px;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 5px #ccc;
}

.About-Me-Page-Container {
  margin-top: -60px;
}

/* Projects Page */
.pj-card-img {
  border-radius: 10px;
  max-width: 100vw;
  height: 70vh;
  box-shadow: 0px 0px 10px 5px #ccc;
  position: absolute;

	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.flex-container {
  display: flex;
  background-color: white;
  margin-top: -50px;
}

.BigVideo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 36px;
}

.BigVideo video {
  width: 100%;
  border-radius: 20px;
}

.BigVideo {
  flex: 2;
  padding-right: 20px;
}

.SmallVideo {
  flex: 2;
}

.DownloadOniOSAppStoreView {
  flex: 1;
  padding-left: 36px;
  padding-right: 36px;
}

.BetaSignUp {
  flex: 2;
  padding-right: 60px;
  margin-right: 70px;
}

/* Media query for responsive layout */
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .flex-container {
      flex-direction: column;
  }

  .BigVideo,
  .DownloadOniOSAppStoreView {
      flex: none; /* Reset flex to its default value */
      margin: 0;
      padding-left: 36px;
      padding-right: 36px;
  }

}